<template>
  <!-- TODO: Replace headerButtons with a button bar component that takes md-buttons as a child (maybe in the future when md-components is a considered project?) -->
  <md-report-layout :header-buttons="headerButtons"
                    :loading="loading"
                    :loading-text="loadingText"
                    ref="reportLayout">

    <template v-slot:header-left>
      <md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged" display-mode="header"/>
    </template>

    <template v-slot:employee-list>
      <!--md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged"/-->

      <md-employee-list :loading="loadingEmployees"
                        :employees="employees"
                        :can-select-multiple="true"
                        :init-all-selected="true"
                        @employeeSelected="onEmployeeSelected"
                        style="height: calc(100vh - 400px); margin-bottom: 10px;"/>

      <div class="header" style="font-size: 13px; padding-left: 5px; font-weight:bold; margin-bottom: 10px; text-align: left">
        {{ countSelectedEmployees }} von {{ countEmployees }} Mitarbeiter sind ausgewählt
      </div>

      <div class="header" style="display:flex; flex-direction: row; justify-content: flex-start;">
        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="selectAllEmployees"
                  style="border-radius: 0; margin-right: 0.5rem;">
          alle auswählen
        </b-button>
        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="deselectAllEmployees"
                  style="border-radius: 0; margin-right: 1rem; justify-self: flex-start;">
          alle abwählen
        </b-button>

        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="toggleShowOnlyActiveEmployees"
                  style="border-radius: 0; margin-left: auto;">
          {{ showOnlyActiveEmployees ? 'alle Mitarbeiter anzeigen' : 'nur aktive Mitarbeiter anzeigen' }}
        </b-button>
      </div>
    </template>

    <template v-slot:report-settings>
      <md-report-config :options="reportConfigData" @configChanged="onReportConfigChange"/>
    </template>

    <template v-slot:report-list>
      <ml-report-list :reports="reports"
                      :loading="loadingReports"
                      @deleteReport="openDeleteReportPopup"
                      @downloadReport="downloadReport"
                      @reportClicked="openReport"/>
    </template>

    <template v-slot:button-bar v-if="creatingReport || !canCreateReport">
      <span v-if="creatingReport">
        <i class="fas fa-circle-notch fa-spin"></i>
        Report wird erstellt
      </span>
      <span v-else-if="!employmentModelsSelected || !wageTypesSelected">
        Bitte mindestens eine Art der Beschäftigungsmodelle und Lohnarten auswählen
      </span>
      <span v-else>
        Bitte mindestens einen Mitarbeiter auswählen
      </span>
    </template>

  </md-report-layout>
</template>

<script>
import moment from 'moment';
import MdReportLayout from '@/components/reportcontext/ReportLayout';
import MdDateSelect from '@/components/uicomponents/md-date-select';
import MlReportList from '@/components/uicomponents/ml-report-list';
import { reportMixin } from '@/components/reportcontext/report-mixin';
import { mapGetters } from "vuex";
import VueEventBus from '../../vue-event-bus';

export default {
  name: 'reportoverviewvariable',
  components: {
    MdReportLayout,
    MdDateSelect,
    MlReportList,
  },
  mixins: [ reportMixin ],
  data() {
    return {
      reportType: 'OVERVIEWVARIABLE',
      loadingText: 'Report Übersicht Variabel wird geladen',

      models: [],
      wageTypes: [],
    }
  },
  created: function () {
    // console.log("CREATED");
    // VueEventBus.$on("JobReportEvent:jobCreated", this.jobCreated);
    // VueEventBus.$on("JobReportEvent:jobStarted", this.jobStarted);
    // VueEventBus.$on("JobReportEvent:jobEnded", this.jobEnded);
    // VueEventBus.$on("JobReportEvent:jobFailed", this.jobFailed);
  },
  beforeDestroy: function () {
    // console.log("BEFORE DESTROY");
    // VueEventBus.$off("JobReportEvent:jobCreated", this.jobCreated);
    // VueEventBus.$off("JobReportEvent:jobStarted", this.jobStarted);
    // VueEventBus.$off("JobReportEvent:jobEnded", this.jobEnded);
    // VueEventBus.$off("JobReportEvent:jobFailed", this.jobFailed);
  },
  destroyed: function () {
    // console.log("DESTROYED");
  },
  mounted() {
    if (this.$store.state.jwt !== undefined && this.$store.state.companyId !== undefined) {

      this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId','companySettings', 'storesSettings','employeeContractMap']),
    headerButtons() {
      let helperLinks = [];
      let buttons = [
        {
          classes: 'add-employeetype',
          type: 'block gray',
          disabled: false,
          icon: 'fa-th-list',
          click: ($event) => {
          },
          show: true,
          tooltip: 'Übersicht Variabel',
          title: 'Übersicht Variabel',
          helperText: 'Die Übersicht Variabel stellt alle variabel eingestellten Schichten dar.',
          //externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/1134428161"
        },
        {
          type: 'block green',
          disabled: this.creatingReport || !this.canCreateReport,
          icon: this.creatingReport ? 'fa-redo' : 'fa-file-check',
          click: ($event) => {
            this.createReportProxy();
          },
          show: true,
          tooltip: 'Report erstellen',
          title: 'Report erstellen',
        },
      ];
      let state = {
        buttons: buttons,
        helperLinks: helperLinks,
        showListIconAlways: true,
      };

      state.helperLinks = [
        {
            icon: "fa-video",
            title: "Schulungsvideo ansehen",
            helperText: "Sieh dir das Video der Basisschulung zu diesem Bereich an um nochmal die Erklärung zu allen grundlegenden Funktionen zu sehen.",
            externalUrl: "https://www.youtube.com/watch_popup?v=BePh4xNJvsY",
            externalLabel: "Basisschulung 'Reports'",
            cancelLabel: "Schließen"
        },
        {
          title: 'Support erhalten',
          helperText: 'Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf \'Support kontaktieren\', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf \'Senden\'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.',
          externalUrl: 'https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/2/create/15',
          externalLabel: 'Support kontaktieren',
          cancelLabel: 'Schließen',
        },
      ];
      return state;
    },

    canCreateReport() {
      return this.employmentModelsSelected && this.wageTypesSelected && this.employeesSelected;
    },
    employmentModelsSelected() {
      const employmentModels = this.reportConfig.employmentModels || {};
      return (employmentModels.selectedModels || []).length > 0;
    },
    wageTypesSelected() {
      const wageTypes = this.reportConfig.wageTypes || {};
      return (wageTypes.selectedWageTypes || []).length > 0;
    },
    employeesSelected() {
      return this.countSelectedEmployees > 0;
    }
  },
  watch: {
    companyId(newVal, oldVal) {
      console.log("COMPANY ID CHANGED", newVal, oldVal);
      this.refresh(true);
    },
    rangeStart() {
      this.updateReportConfig('reportname', this.getReportName());
    },
  },
  // methods that implement data (BUSINESS) logic. (https://en.wikipedia.org/wiki/Business_logic)
  methods: {
    getReportName() {
      return this.rangeMode === 'monthlyRange'
          ? `Übersicht Variabel, ${ moment(this.rangeStart).format('MMMM YYYY') }`
          : `Übersicht Variabel, ${ moment(this.rangeStart).format('DD.MM.YYYY') } bis ${ moment(this.rangeEnd).format('DD.MM.YYYY') }`;
    },

    async refresh(force = false) {
      this.loading = true;
      this.updateReportConfig('reportname', this.getReportName());

      try {
        await Promise.all([
          this.$helpers.GetCompanySettings(force),
          this.$helpers.GetStoresSettings(force),
          this.$helpers.GetExportSettingsMap(force),
          this.$helpers.GetEmployeeContractMap(force),
          this.loadModels(),
          this.loadWageTypes(),
          this.loadReports(this.reportType),
          this.loadEmployees(true),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.generateReportConfigOpts();

        // console.log(this.getReportConfigValue('reportname'));
        // reportConfig can be loaded after generating the options, as reportConfig does not overwrite the options
        this.reportConfig = this.loadReportConfig();
        this.updateReportConfig('reportname', this.getReportName());

        console.log(this.reports);

        this.loading = false;
      }
    },

    generateReportConfigOpts() {
      let view = this;
      let disabled = !this.CheckEnableConfigEdit
      let specialDisabled = !this.CheckEnableConfigSpecialEdit
      const nameSection = this.createReportConfigSection('Dokumentname', true);
      this.addTextConfigOption(nameSection, 'reportname', 'Dokumentname eingeben', this.getReportName(), true);

      // const commentSection = this.createReportConfigSection('Kommentar');
      // this.addTextConfigOption(commentSection, 'reportcomment', 'Kommentar eingeben');


      const formatSection = this.createReportConfigSection('Dateiformat', false, disabled);
      this.addSelectConfigOption(formatSection, 'fileformat', [
        this.addSelectableValue('PDF', 'pdf', true),
        // this.addSelectableValue('CSV', 'csv'),
      ]);


      console.log(...this.storesSettings.data.map(store => this.addSelectableValue(store.name, store.id)));
      const storeSection = this.createReportConfigSection('Store', false, disabled);
      this.addSelectConfigOption(storeSection, 'storeId', [
        ...this.storesSettings.data.map(store => this.addSelectableValue(store.name, store.id))
      ]);

      const contentSection = this.createReportConfigSection('Dokumentinhalt', false, disabled);
      view.addSelectConfigOption(contentSection, 'employeeSelection', [
        view.addSelectableValue('Alle Mitarbeiter', 1, true),
        view.addSelectableValue('Nur Mitarbeiter mit ausgewählten Werten', 3),
      ]);
      view.addSelectConfigOption(contentSection, 'employmentModels', [
        view.addSelectableValue('Alle Beschäftigungsgruppen',
          view.models.map(model => view.addSelectableValue(model.name, model.id)),
          view.models.map(model => model.id),
          'selectedModels')
      ], true);
      view.addSelectConfigOption(contentSection, 'wageTypes', [
        view.addSelectableValue('Alle Lohnarten',
          view.wageTypes.map(wageType => view.addSelectableValue(wageType.name, wageType.id)),
          view.wageTypes.map(wageType => wageType.id),
          'selectedWageTypes')
      ], true);
      view.addSelectConfigOption(contentSection, 'showOnlySums', [
        view.addSelectableValue('Alle Werte anzeigen', false, true),
        view.addSelectableValue('Nur Summen anzeigen', true),
      ]);
      view.addSelectConfigOption(contentSection, 'sums', [
        view.addSelectableValue('Alle Summen', [
            view.addSelectableValue('Mitarbeiter Summen', 'employeeSums', true),
            view.addSelectableValue('Beschäftigungsgruppe Summen', 'employmentModelSums', true),
            view.addSelectableValue('Lohnart Summen', 'wageTypeSums', true),
            view.addSelectableValue('Gesamt Summen', 'totalSums', true),
          ],
          ['employeeSums', 'employmentModelSums', 'wageTypeSums', 'totalSums'],
          'selectedSums')
      ], true);

      const groupSection = this.createReportConfigSection('Gruppierung', false, specialDisabled);
      this.addSelectConfigOption(groupSection, 'groupBy', [
        this.addSelectableValue('Mitarbeiter nicht gruppieren', 0, true),
        this.addSelectableValue('Mitarbeiter nach Beschäftigungsgruppe gruppieren', 1),
      ]);

      const sortSection = this.createReportConfigSection('Sortierung', false, specialDisabled);
      this.addSelectConfigOption(sortSection, 'sortEmployeesBy', [
        this.addSelectableValue('Mitarbeiter nach Nachnamen sortieren', 0, true),
        this.addSelectableValue('Mitarbeiter nach Personalnummer sortieren', 1),
      ]);
      this.addSelectConfigOption(sortSection, 'sortEntriesBy', [
        this.addSelectableValue('Einzelposten nach EarnCode sortieren', 0, true),
        this.addSelectableValue('Einzelposten nach Datum sortieren', 1),
      ]);



      const headerFormatSection = this.createReportConfigSection('Kopfzeile', false, specialDisabled);
      this.addSelectConfigOption(headerFormatSection, 'settingDateFormat', [
        this.addSelectableValue('DD.MM.YYYY', 'DD.MM.YYYY', true),
        this.addSelectableValue('YYYY-MM-DD', 'YYYY-MM-DD'),
      ]);

    },

    async createReportProxy() {
      if (this.rangeMode === 'customRange') {

        if (this.rangeStart.month() != this.rangeEnd.month() || this.rangeStart.year() != this.rangeEnd.year()) {
          this.$helpers.error('Fehler', 'Start und Enddatum müssen im selben Monat liegen!');
          return;
        }
        if (this.rangeStart > this.rangeEnd) {
          this.$helpers.error('Fehler', 'Startdatum muss vor Enddatum liegen!');
          return;
        }

      }
      await this.generateReport();
    },

    async generateReport() {
      this.creatingReport = true;

      const employeeIds = this.selectedEmployeeIds;

      const dateFormats = {
        'DD.MM.YYYY': 'dmY',
        'YYYY-MM-DD': 'Ymd',
      };
      const dateFormat = dateFormats[this.reportConfig.settingDateFormat] || 'dmY';

      const hourFormats = {
        '3': '3',
        '2': '2',
        'Hm': 'Hm',
      };
      const hourFormat = hourFormats[this.reportConfig.settingHourFormat] || 'Hm';

      const name = this.reportConfig.reportname;
      // const comment = this.reportConfig.reportcomment;
      const config = {
        'year': this.rangeStart.year(),
        'month': this.rangeStart.month() + 1,
        'headerDateFormat': dateFormat,
        'hourFormat': hourFormat,
        'storeId': this.reportConfig.storeId,
        'employmentModelsBool': false,
        'employmentModels': this.reportConfig.employmentModels.selectedModels,
        'wageTypesBool': false,
        'wageTypes': this.reportConfig.wageTypes.selectedWageTypes,
        'employeeSelection': this.reportConfig.employeeSelection,
        'showOnlySums': this.reportConfig.showOnlySums,
        'employeeSums': this.reportConfig.sums.selectedSums.includes('employeeSums'),
        'employmentModelSums': this.reportConfig.sums.selectedSums.includes('employmentModelSums'),
        'wageTypeSums': this.reportConfig.sums.selectedSums.includes('wageTypeSums'),
        'totalSums': this.reportConfig.sums.selectedSums.includes('totalSums'),
        'groupBy': this.reportConfig.groupBy,
        'sortEmployeesBy': this.reportConfig.sortEmployeesBy,
        'sortEntriesBy': this.reportConfig.sortEntriesBy,
        'headerLogo': 'ml',
        'fileformat': this.reportConfig.fileformat ?? 'pdf'
      };

      if (this.rangeMode === 'customRange') {


        config.fromDate = this.rangeStart.format();
        config.toDate = this.rangeEnd.format();

      }

      console.log(employeeIds);
      console.log(config);

      // store started report in DB
      let reportId = 0;
      this.storeStartedReport(this.reportType, name, "", config)
      .then((response) => {
        // get reportId
        reportId = response.data.data.id;

        let entry = {
          created_at: "",
          exportJson: "",
          month: this.rangeStart.month() + 1,
          year: this.rangeStart.year(),
          storeId: config.storeId,
          options: config,
        };
        // console.log(entry);

        let employeeMapById = {};
        Object.values(this.employeeMap).filter(e => employeeIds.includes(e.id)).map(e => employeeMapById[e.id] =  e); // map to employee by id
        let contracts = this.employeeContractMap; // contracts
        let models = this.models; // models
        let store = this.storesSettings.data.find(store => store.id === this.reportConfig.storeId); // store
        let userFullname = this.$store.state.userFullname; // userFullname

        // console.log(employeeMapById);

        // get the export data
        this.$helpers.calculateIndividualWageTypeExport(entry)
          .then((response) => {
            console.log(response);

            entry.exportJson = JSON.stringify(response.data);

            // generate pdf
            let [doc, filename] = this.$helpers.generateSotecExportPDF(entry, employeeMapById, contracts, models, store, userFullname);

            let blob = doc.output('blob');

            // store pdf in DB
            this.storeFinishedReport(this.reportType, reportId, new File([blob], filename));
            this.creatingReport = false;
          })
          .catch((error) => {
            console.error(error);
            this.creatingReport = false;
          });

      });
    },
    jobCreated: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB CREATED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 50);
    },
    jobStarted: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB STARTED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 100);
    },
    jobEnded: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB ENDED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    jobFailed: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB FAILED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    reloadDocuments: function () {
      this.loadReports(this.reportType);
    },



    loadModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      return view.$helpers
      .GetCall("/api/sec/employmentModels", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
          };
          tmp.push(item);
        }
        view.models = tmp.slice();
        console.log("models", view.models);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    loadWageTypes: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      return view.$helpers
      .GetCall("/api/sec/individualWageTypes", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            numberString: data[i].numberString,
            name: data[i].name,
            details: data[i].details,
          };
          tmp.push(item);
        }
        view.wageTypes = tmp.slice();
        console.log("wagetypes", view.wageTypes);
      })
      .catch((e) => {
        console.log(e);
      });
    },

  },
}
</script>

<style scoped>
/* remove max-height from sub component: 100vh would cut off the list of employmentmodels and wagetypes when the list is too long */
>>>.config-entry>.body.entry-expanded {
  max-height: 100%;
}
</style>
